<template>
  <div class="group">
    <el-header class="group-header">
      <div class="home-title">
        <img src="../../assets/img/logo.png" alt="" width="38px" height="38px">
        <span>捷信云物联网大数据服务平台</span>
      </div>
    </el-header>
    <div class="group-wrap">
      <div class="camera-monitor">
        <div class="camera-box" ref="cameraBox">
          <HikVision ref="hik" :width="width" :height="height" :pointCode="cameraCode" :layout="'1x2'" v-if="show" :info="cameraInfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HikVision from '../../components/HikVision.vue'
export default {
  components: {
    HikVision
  },
  data () {
    return {
      width: 0,
      height: 0,
      cameraInfo: {
        appKey: '28022728',
        appSecret: 'wACQUoEN8q08bu1DzHL1',
        host: '61.180.39.9:1443'
      },
      cameraCode: 'cf34fb128cec480a88e27735f785ea9b,dbda2d0385334c569840821d440269aa',
      show: false
    }
  },
  mounted () {
    this.width = this.$refs.cameraBox.offsetWidth
    this.height = this.$refs.cameraBox.offsetHeight
    this.show = true
  }
}
</script>

<style lang="scss" scoped>
.group {
  .group-header {
    color: #39b8e6;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 76px !important;
    font-size: 18px;
    padding: 0 1%;
    background: url("../../assets/img/bg-header.png") no-repeat center center;
    background-size: 100% 100%;
    .home-title {
      height: 100%;
      width: 20%;
      padding: 0 0.1%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 100% 100%;
      color: #fff;
      span {
        letter-spacing: 2px;
      }
    }
  }
  .group-wrap {
    height: calc(100vh - 76px);
    width: 100%;
    display: flex;
    padding: 0 2% 2% 2%;
    box-sizing: border-box;
    background: url("../../assets/img/bg-body.png") no-repeat center center;
    background-size: 100% 100%;
    .camera-monitor {
      width: 100%;
      height: 100%;
      .camera-box {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  }
}
</style>
